import {
  DraftConverter,
  mapBlockToNode,
  MapBlockToNodeContext,
  MapEntityToMarkContext,
  mapEntityToMark,
  mapInlineStyleToMark,
  MapInlineStyleToMarkContext,
} from '@tiptap/draftjs-to-tiptap';
import { JSONContent } from '@tiptap/react';

import { AccountResponse } from '@bloom/codegen/models/AccountResponse';
import { UserResponse } from '@bloom/codegen/models/UserResponse';

import { SharedQueryKeyEnum } from '@bloom/library/components/hooks/interface';
import { getQueryClient } from '@bloom/library/utils/get-query-client';

export function convertDraftJsToTiptap(content: string): JSONContent | null {
  try {
    const queryClient = getQueryClient();
    const me = queryClient.getQueryData<UserResponse>([SharedQueryKeyEnum.USER_ME]);
    const account = queryClient.getQueryData<AccountResponse>([
      SharedQueryKeyEnum.ACCOUNT,
      me?.defaultAccountId,
    ]);

    const draftJsContent = JSON.parse(content);

    if ('type' in draftJsContent && draftJsContent.type === 'doc') {
      // This means the state is already converted from Draft.js to Tiptap
      // Just return the object
      return draftJsContent;
    }

    const convertDraftToTiptap = new DraftConverter({
      mapBlockToNode(ctx: MapBlockToNodeContext) {
        const { block, entityMap } = ctx;

        if (!block) {
          return null;
        }

        if (block.type === 'photographer-avatar') {
          return {
            attrs: { src: block.data.src || me?.avatar, width: 40 },
            type: 'avatar',
          };
        }

        if (block.type === 'photographer-logo') {
          return {
            attrs: { src: block.data.src || account?.logo },
            type: 'logo',
          };
        }

        // Use default handler for non-unstyled blocks
        if (block.type !== 'unstyled') {
          return mapBlockToNode(ctx);
        }

        // Check if block contains any VARIABLE entities
        const hasVariables = block.entityRanges.some(
          (range) => entityMap[range.key].type === 'VARIABLE'
        );

        if (!hasVariables) {
          if (block.text === '') {
            return {
              type: 'paragraph',
            };
          }

          return mapBlockToNode(ctx);
        }

        // Process block with variables
        const content: any[] = [];
        let lastIndex = 0;

        // Sort entity ranges by offset
        const sortedRanges = [...block.entityRanges].sort((a, b) => a.offset - b.offset);

        // Process each range
        sortedRanges.forEach((range) => {
          // Add text before the entity if exists
          if (range.offset > lastIndex) {
            content.push({
              text: block.text.slice(lastIndex, range.offset) || ' ',
              type: 'text',
            });
          }

          const entity = entityMap[range.key];
          if (entity.type === 'VARIABLE') {
            content.push({
              attrs: {
                displayValue: entity.data.displayName ?? entity.data.display ?? '',
                token: entity.data.value || '',
                value: block.text.slice(range.offset, range.offset + range.length),
              },
              type: 'variable',
            });
          } else {
            content.push({
              text: block.text.slice(range.offset, range.offset + range.length) || ' ',
              type: 'text',
            });
          }

          lastIndex = range.offset + range.length;
        });

        // Add remaining text if exists
        if (lastIndex < block.text.length) {
          content.push({
            text: block.text.slice(lastIndex) || ' ',
            type: 'text',
          });
        }

        return {
          content,
          type: 'paragraph',
        };
      },

      mapEntityToMark: (ctx: MapEntityToMarkContext) => {
        const { entityMap, range } = ctx;
        const entity = entityMap[range.key];

        if (entity.type === 'VARIABLE') {
          return null;
        }

        return mapEntityToMark(ctx);
      },
      mapInlineStyleToMark: (ctx: MapInlineStyleToMarkContext) => {
        const mark = mapInlineStyleToMark(ctx);

        if (['highlight', 'textStyle'].includes(mark?.type || '')) {
          return null;
        }

        return mapInlineStyleToMark(ctx);
      },
    });

    return convertDraftToTiptap.convert(draftJsContent);
  } catch (e) {
    console.error('Error converting Draft.js content to Tiptap:', e);

    if (typeof content === 'string') {
      return {
        content: [{ content: [{ text: content || ' ', type: 'text' }], type: 'paragraph' }],
        type: 'doc',
      };
    }

    return null;
  }
}
